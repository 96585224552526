
.territory-no-analysis-drop-down {
  background-color : white;
  position : fixed;
  left : 33.3%;
  bottom: var(--navigation-height);
  width : 33.3%;
  height: calc(var(--navigation-height)*2);
  z-index : 20;
  display : grid;
  grid-column : 1 / span 3;
  border-right : 1px solid black;
  border-left : 1px solid black;
  border-top : 1px solid black;
  border-bottom : 1px solid black;
  text-align : center;
}
