
.radio-button-up {
  background-color : var(--bg);
  padding-top: 15px;
  padding-bottom: 15px;
  width:100%;
  border: 1px solid;
  text-align: center !important;
  cursor : pointer;
}

.radio-button-down {
  text-align: center !important;
  background-color: black;
  text-transform: none;
  color:var(--bg);
  padding-top: 15px;
  padding-bottom: 15px;
  width:100%;
  cursor : pointer;
  border: 1px solid;
}

.radio-button-drop-up {
  background-color : var(--bg);
  padding:0.6em;
  width:100%;
  border-top: 1px solid;
  cursor : pointer;
  font-size: 1em;
  transition:0.3s ease;
  text-align: center !important;

}


.radio-button-drop-up:hover {
color:var(--bg);
background-color: var(--dark-blue);
}
