.info-box {
  border: 1px solid var(--dark-blue);
  background-color: #5151fc1a;
  padding: 2rem;
  display: inline-block;
  border-radius: 6px;
  font-size: 1rem !important;
  margin: 2rem 0 3rem;
}
.info-box:before {
  content: "Nota sulla terminologia";
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.6rem;
}
.info-box.it:before {
  content: "Nota sulla terminologia";
}
.info-box.en:before {
  content: "Note on the terminology";
}
.info-box * {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.info-box p {
  margin-bottom: 0.9rem;
}

.info-box ul {
  margin: 0;
  padding-left: 0;
}

.info-box li {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}
.info-box li:before {
  content: "–";
  position: absolute;
  left: 0.2rem;
}

.capta-list {
  grid-column: 4 / span 6;
  border-right: 1px solid black;
}

.capta-list ul {
  margin: 0;
  height: calc(100vh - 76px);
  padding: 3.63rem 0;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.capta-list li {
  margin: 0;
  cursor: pointer;
  position: relative;
  left: 0;
  transition: all 0.5s ease;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.capta-list li:hover {
  /* color: var(--dark-blue); */
  left: 1rem;
}

.capta-list li.active-capta {
  color: var(--dark-blue);
  left: 0.5rem;
}

.capta-focus {
  grid-column-start: 11;
  grid-column-end: -4;
  padding: 2rem 0;
}

.downloadArea {
  display: flex;
  align-items: center;
  margin: 3rem 0 2.5rem;
  flex-wrap: wrap;
}

.dataset-download {
  background-color: var(--dark-blue);
  height: 31px;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  color: white;
  padding: 0 1rem;
  opacity: 0.9;
  margin-right: 1rem;
  outline: none;
  margin-bottom: 1rem;
}

.dataset-download:hover,
.dataset-download:focus {
  opacity: 1;
  color: white;
}

.dataset-download.disabled {
  background-color: #aaa;
  color: #eee;
}

.dataset-license {
  height: 31px;
  display: inline-flex;
  margin-bottom: 1rem;
}

.capta-focus li {
  position: relative;
}

.capta-focus li:before {
  content: "–";
  padding: 0 0.5rem 0 0.2rem;
  position: absolute;
  left: -1.5rem;
}

.tag {
  background-color: #afaff94d;
  padding: 0 5px 2px;
  border-radius: 3px;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1440px) {
  .capta-focus {
    grid-column-end: -6;
  }
}
