.development > * {
  pointer-events: none;
}

.IndexMenu {
  /* width: 100vw; */
  position: relative;
  height: calc(100vh - (var(--navigation-height) + 18px));
  pointer-events: none;
}

.IndexMenu > * {
  pointer-events: auto;
}

.OrbiteContainer {
  position: absolute;
  pointer-events: none;
  background-color: var(--cool-bg);
}

.icon-approfondimento {
  left: 45%;
  width: 100%;
  position: absolute;
  top: 100%;
}

.icon-approfondimento:hover{
  left: 15%;
  transition: linear 0.5s;
}

.text-approfondimento {
  opacity: 0;
}

.icon-approfondimento:hover > .text-approfondimento{
  opacity: 1;
  transition: ease-in 1s;
}

.small-approfondimento{
  font-size: 10px;
  color: #808080;
  line-height: 10px;
}

.tappa1 {
  position: absolute;
  /* top: calc(calc(100vh / 5.25) - var(--navigation-height));
  left: calc(100% / 12 * 7); */
  width: 44px;
  height: 44px;
}

.tappa2 {
  position: absolute;
  /* top: calc(calc(100vh / 5.75) - var(--navigation-height));
  left: calc(100% / 12 * 7.5); */
  width: 44px;
  height: 44px;
}

.tappa3 {
  position: absolute;
  top: calc(calc(100vh / 6.25) - var(--navigation-height));
  left: calc(100% / 12 * 8);
  width: 44px;
  height: 44px;
}

.tappe {
  color: var(--dark-green);
  text-transform: uppercase;
  position: absolute;
  top: calc(calc(100vh / 7.5) - var(--navigation-height));
  left: calc(100% / 12 * 7.4);
  font-size: 14px;
  font-weight: bold;
}

.title-viz {
  font-size: 16px;
  font-weight: 700;
}


.icon-width {
  width: 80px;
}


/* Icons position and size */
.territorio .title-viz {
  background: rgb(255,255,250);
  padding:0 0.4em 0.4em 0.4em;
}

.territorio {
  position: absolute;
  top: calc(calc(100vh / 6) - var(--navigation-height));
  left: calc(100% / 12 * 5.5);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dubitare {
  position: absolute;
  /* top: calc(calc(100vh / 8 * 2.75) - var(--navigation-height));
  left: calc(100% / 12); */
  top: 48.490842%;
  left: 14.431613%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.curve-dubbio-1 {
  position: absolute;
  top: calc(calc(100vh / 8 * 3) - var(--navigation-height));
  left: calc(100% / 12 * 2.25);
}

.curve-dubbio-2 {
  position: absolute;
  top: calc(calc(100vh / 8 * 5) - var(--navigation-height));
  left: calc(100% / 12 * 1.75);
}

.curve-spazio-1 {
  position: absolute;
  top: calc(calc(100vh / 8 * 4.75) - var(--navigation-height));
  left: calc(100% / 12 * 5.25);
}

.curve-spazio-2 {
  position: absolute;
  top: calc(calc(100vh / 8 * 6.5) - var(--navigation-height));
  left: calc(100% / 12 * 5);
}

.curve-forma-1 {
  position: absolute;
  top: calc(calc(100vh / 8 * 3) - var(--navigation-height));
  left: calc(100% / 12 * 9.25);
}

.curve-forma-2 {
  position: absolute;
  top: calc(calc(100vh / 8 * 4.8) - var(--navigation-height));
  left: calc(100% / 12 * 9.7);
}

.nebbia {
  position: absolute;
  /* top: calc(calc(100vh / 8 * 2) - var(--navigation-height));
  left: calc(100% / 12 * 3); */
  top: 30.100334448160538%;
  left: 29.270833333333336%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cancellazione {
  position: absolute;
  /* top: calc(calc(100vh / 8 * 5.25) - var(--navigation-height));
  left: calc(100% / 12 * 1.5); */
  left: 17.049666419570052%;
  top: 76.54986522911051%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.trasformare {
  position: absolute;
  /* top: calc(calc(100vh / 8 * 4.25) - var(--navigation-height));
  left: calc(100% / 12 * 4); */
  left: 38.072916666666664%;
  top: 57.63656633221851%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.luoghi {
  position: absolute;
  /* top: calc(calc(100vh / 8 * 2.75) - var(--navigation-height));
  left: calc(100% / 12 * 5.5); */
  left: 49.53125%;
  top: 38.46153846153847%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.realismo {
  position: absolute;
  /* top: calc(calc(100vh / 8 * 6) - var(--navigation-height));
  left: calc(100% / 12 * 6); */
  left: 54.79166666666667%;
  top: 83.61204013377926%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.elenchi {
  position: absolute;
  /* top: calc(calc(100vh / 8 * 2) - var(--navigation-height));
  left: calc(100% / 12 * 8); */
  left: 71.82291666666667%;
  top: 29.76588628762542%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.combinare {
  position: absolute;
  top: calc(calc(100vh / 8 * 2.75) - var(--navigation-height));
  left: calc(100% / 12 * 10);
  left: 86.40625%;
  top: 46.490842%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.trama {
  position: absolute;
  top: calc(calc(100vh / 8 * 5.25) - var(--navigation-height));
  left: calc(100% / 12 * 9.5);
  left: 83.95833333333333%;
  top: 74.47045707915272%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.basic-icon {
  display: block;
}

.hoverable-icon .hover-icon {
  display: none;
}

.hoverable-icon:hover .basic-icon {
  display: none;
}

.hoverable-icon:hover .hover-icon {
  display: block;
}

.guarda-la-guida {
  bottom: var(--footer-height);
  right: 0;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-top: 1px solid var(--dark-blue);
  border-left: 1px solid var(--dark-blue);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .icon-width {
    width: 90px;
  }

  .territorio .icon-width{
    width:115px;
  }

  .big-name {
    font-size: 45px;
    letter-spacing: 1px;
    font-weight: 600;
    color: var(--violet-index-home);
  }

  .tappe {
    color: var(--dark-green);
    text-transform: uppercase;
    position: absolute;
    top: calc(calc(100vh / 9) - var(--navigation-height));
    left: calc(100% / 12 * 7.3);
    font-size: 14px;
    font-weight: bold;
  }

  .tappa1 {
    position: absolute;
    /* top: calc(calc(100vh / 5.25) - var(--navigation-height));
    left: calc(100% / 12 * 7); */
    width: 44px;
    height: 44px;
  }

  .tappa2 {
    position: absolute;
    /* top: calc(calc(100vh / 5.75) - var(--navigation-height));
    left: calc(100% / 12 * 7.5); */
    width: 44px;
    height: 44px;
  }

  .tappa3 {
    position: absolute;
    top: calc(calc(100vh / 6.5) - var(--navigation-height));
    left: calc(100% / 12 * 8);
    width: 44px;
    height: 44px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .icon-width {
    width: 140px;
  }

  .big-name {
    font-size: 45px;
    letter-spacing: 1px;
    font-weight: 600;
    color: var(--violet-index-home);
  }

  .tappe {
    color: var(--dark-green);
    text-transform: uppercase;
    position: absolute;
    top: calc(calc(100vh / 9) - var(--navigation-height));
    left: calc(100% / 12 * 7.42);
    font-size: 14px;
    font-weight: bold;
  }

  .tappa1 {
    position: absolute;
    /* top: calc(calc(100vh / 5.75) - var(--navigation-height));
    left: calc(100% / 12 * 7); */
    width: 44px;
    height: 44px;
  }

  .tappa2 {
    position: absolute;
    /* top: calc(calc(100vh / 6.25) - var(--navigation-height));
    left: calc(100% / 12 * 7.5); */
    width: 44px;
    height: 44px;
  }

  .tappa3 {
    position: absolute;
    top: calc(calc(100vh / 7) - var(--navigation-height));
    left: calc(100% / 12 * 8);
    width: 44px;
    height: 44px;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
  .icon-width {
    width: 130px;
  }

  .big-name {
    font-size: 60px;
    letter-spacing: 1px;
    font-weight: 600;
    color: var(--violet-index-home);
  }
}

@media screen and (min-width: 1600px) and (max-width: 1699px) {
  .icon-width {
    width: 150px;
  }

  .tappe {
    color: var(--dark-green);
    text-transform: uppercase;
    position: absolute;
    top: calc(calc(100vh / 9) - var(--navigation-height));
    left: calc(100% / 12 * 7.42);
    font-size: 14px;
    font-weight: bold;
  }

  .tappa1 {
    position: absolute;
    /* top: calc(calc(100vh / 5.25) - var(--navigation-height));
    left: calc(100% / 12 * 7); */
    width: 44px;
    height: 44px;
  }

  .tappa2 {
    position: absolute;
    /* top: calc(calc(100vh / 6) - var(--navigation-height));
    left: calc(100% / 12 * 7.5); */
    width: 44px;
    height: 44px;
  }

  .tappa3 {
    position: absolute;
    top: calc(calc(100vh / 6.5) - var(--navigation-height));
    left: calc(100% / 12 * 8);
    width: 44px;
    height: 44px;
  }

  .big-name {
    font-size: 60px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #afaff9;
  }
}

@media screen and (min-width: 1700px) and (max-width: 1999px) {
  .icon-width {
    width: 180px;
  }


  .big-name {
    font-size: 60px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #afaff9;
  }

  .tappe {
    color: var(--dark-green);
    text-transform: uppercase;
    position: absolute;
    top: calc(calc(100vh / 9) - var(--navigation-height));
    left: calc(100% / 12 * 7.42);
    font-size: 14px;
    font-weight: bold;
  }

  .tappa1 {
    position: absolute;
    /* top: calc(calc(100vh / 5.5) - var(--navigation-height));
    left: calc(100% / 12 * 7); */
    width: 44px;
    height: 44px;
  }

  .tappa2 {
    position: absolute;
    /* top: calc(calc(100vh / 6.15) - var(--navigation-height));
    left: calc(100% / 12 * 7.5); */
    width: 44px;
    height: 44px;
  }

  .tappa3 {
    position: absolute;
    top: calc(calc(100vh / 6.75) - var(--navigation-height));
    left: calc(100% / 12 * 8);
    width: 44px;
    height: 44px;
  }

  .curve-combinare {
    position: absolute;
    top: calc(calc(100vh / 8 * 5) - var(--navigation-height));
    left: calc(100% / 12 * 10.5);
    width: 300px;
  }
}

@media screen and (min-width: 2000px) {
  .icon-width {
    width: 200px;
  }

  .tappe {
    color: var(--dark-green);
    text-transform: uppercase;
    position: absolute;
    top: calc(calc(100vh / 9) - var(--navigation-height));
    left: calc(100% / 12 * 7.42);
    font-size: 14px;
    font-weight: bold;
  }

  .tappa1 {
    position: absolute;
    /* top: calc(calc(100vh / 6) - var(--navigation-height));
    left: calc(100% / 12 * 7); */
    width: 44px;
    height: 44px;
  }

  .tappa2 {
    position: absolute;
    /* top: calc(calc(100vh / 6.75) - var(--navigation-height));
    left: calc(100% / 12 * 7.5); */
    width: 44px;
    height: 44px;
  }

  .tappa3 {
    position: absolute;
    top: calc(calc(100vh / 7.5) - var(--navigation-height));
    left: calc(100% / 12 * 8);
    width: 44px;
    height: 44px;
  }
}

@media (min-aspect-ratio: 3/4) {
  .territorio {
    top: calc(calc(100vh / 7) - var(--navigation-height));
    left: calc(100% / 12 * 5.45);
    
  }
}

@media (min-aspect-ratio: 1/1) {
  .territorio {
    top: calc(calc(100vh /8) - var(--navigation-height));
    left: calc(100% / 12 * 5.55);
  }
}

/* 
@media (min-aspect-ratio: 2/3) {
  .territorio {
    top: calc(calc(100vh /10) - var(--navigation-height));
    left: calc(100% / 12 * 5.35);
  }
} */

@media (min-aspect-ratio: 4/3) {
  .territorio {
    top: calc(calc(100vh / 9) - var(--navigation-height));
    left: calc(100% / 12 * 5.45);
  }
}


@media (min-aspect-ratio: 16/9) {
  .territorio {
    top: calc(calc(100vh / 11) - var(--navigation-height));
    left: calc(100% / 12 * 5.4);
  }
}