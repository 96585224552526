
.node .label {
  opacity: 0; /* comment his row to keep labels always visible */
  transition: opacity .5s;
  pointer-events: none;
  font-family: "HK Grotesk";
  letter-spacing: 0.02rem;
}

.node:hover .label, .node .label.visible {
  opacity: 1;
}

.label .white-shadow {
  stroke: var(--bg);
  stroke-width: 0.4rem;
  fill: var(--bg);
  stroke-linejoin: round;
}

.node circle {
  shape-rendering: geometricprecision;
}

.territory-body > div > div > svg > g > .overlay{
  fill: none;
}
