
.territory-bottom-panel {
  background-color : var(--bg);
  outline: 1px solid black;
  position : fixed;
  bottom: var(--navigation-height);
  width : 100%;
  height: calc(var(--navigation-height)*3);
  z-index : 2;
  display : grid;
}


h4{
  text-transform: uppercase;
  font-size: 10px;
}


.title-panel{
  display: block;
}
