/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 30, 2019 */



@font-face {
    font-family: 'HKGrotesk';
    src: url('HKGrotesk-Bold.eot');
    src: url('HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
         url('HKGrotesk-Bold.woff2') format('woff2'),
         url('HKGrotesk-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'HKGrotesk';
    src: url('hkgrotesk-bolditalic.eot');
    src: url('hkgrotesk-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('hkgrotesk-bolditalic.woff2') format('woff2'),
         url('hkgrotesk-bolditalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;

}




@font-face {
    font-family: 'HKGrotesk';
    src: url('hkgrotesk-italic.eot');
    src: url('hkgrotesk-italic.eot?#iefix') format('embedded-opentype'),
         url('hkgrotesk-italic.woff2') format('woff2'),
         url('hkgrotesk-italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;

}




@font-face {
    font-family: 'HKGrotesk';
    src: url('HKGrotesk-Light.eot');
    src: url('HKGrotesk-Light.eot?#iefix') format('embedded-opentype'),
         url('HKGrotesk-Light.woff2') format('woff2'),
         url('HKGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'HKGrotesk';
    src: url('hkgrotesk-lightitalic.eot');
    src: url('hkgrotesk-lightitalic.eot?#iefix') format('embedded-opentype'),
         url('hkgrotesk-lightitalic.woff2') format('woff2'),
         url('hkgrotesk-lightitalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;

}




@font-face {
    font-family: 'HKGrotesk';
    src: url('HKGrotesk-Medium.eot');
    src: url('HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
         url('HKGrotesk-Medium.woff2') format('woff2'),
         url('HKGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}




@font-face {
    font-family: 'HKGrotesk';
    src: url('hkgrotesk-mediumitalic.eot');
    src: url('hkgrotesk-mediumitalic.eot?#iefix') format('embedded-opentype'),
         url('hkgrotesk-mediumitalic.woff2') format('woff2'),
         url('hkgrotesk-mediumitalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;

}




@font-face {
    font-family: 'HKGrotesk';
    src: url('HKGrotesk-Regular.eot');
    src: url('HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
         url('HKGrotesk-Regular.woff2') format('woff2'),
         url('HKGrotesk-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}




@font-face {
    font-family: 'HKGrotesk';
    src: url('HKGrotesk-SemiBold.eot');
    src: url('HKGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('HKGrotesk-SemiBold.woff2') format('woff2'),
         url('HKGrotesk-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'HKGrotesk';
    src: url('hkgrotesk-semibolditalic.eot');
    src: url('hkgrotesk-semibolditalic.eot?#iefix') format('embedded-opentype'),
         url('hkgrotesk-semibolditalic.woff2') format('woff2'),
         url('hkgrotesk-semibolditalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;

}
