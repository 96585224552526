::-webkit-scrollbar {
  display: none;
  /* chrome */
}

#mainGrid {
  border-right: var(--app-border);
  padding: 4em 4em 13em 8em;
  position: fixed;
  height: 100%;
  overflow: scroll;
}

.unscrollable-column {
  left: 0% !important;
  padding: 4em 8em 4em 4em !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: hidden;
}

.itineraries-block {
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  position: fixed;
  height: 100%;
  overflow: none;
}

.itineraries-small-text{
  font-size: 10px;
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.2px;
}

#doubt {
  margin-top: -40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  margin-bottom: 6em;
  height: 100vh;
}

#space {
  margin-top: -40px;
  padding-top: 40px;
  margin-bottom: 6em;
  height: 100vh;
}

#form {
  margin-top: -40px;
  padding-top: 40px;
  margin-bottom: 20em;
  height: 100vh;
}
