.topAxisRect {
  stroke: #fff;
  stroke-width: 1px;
  fill: #000;
  transition: fill 0.5s linear;
}

.topAxisRectEmpty{
  stroke: transparent;
  stroke-width: 1px;
  fill: transparent;
}

.topAxisRectNoSeq{
    stroke: #fff;
  stroke-width: 1px;
  fill:#ccc;
  opacity: 0.3;
}

.topAxisText {
  font-size: 10px;
  fill: #000;
  transition: fill 0.5s linear;
}

.topAxisTextEmpty {
    font-size: 10px;
    fill: transparent;
}

.topAxisTextNoSeq{
    font-size: 10px;
    fill: #000;
    opacity: 0.3;
}

.marimekkoXaxisText{
    font-size: 12px;
    font-style: italic;
    padding-top: 10px;
}

.marimekkoRect {
  fill: transparent;
  stroke: #444;
  stroke-width: 0.5;
}

.marimekkoUnit {
  stroke: #fff;
  stroke-width: 0.2;
  transition: fill 0.5s linear;
}

.marimekkoUnitIceCycle {
    transition: opacity 1s linear;
}

.withLegendItemSelected .marimekkoUnit {
  opacity: 0.2;
  /* stroke-opacity: 0.5; */
}

.withLegendItemSelected .marimekkoUnit.selected {
  opacity: 1;
}

.legendGroupText {
  font-size: 14px;
}

.legendEntryColor {
  height: 10px;
  width: 30px;
  border-radius: 3px;
  margin-right: 6px;
}

.legendItemsSelected .legendEntryColor {
  opacity: 0.2;
}

.legendItemsSelected .selected .legendEntryColor {
  opacity: 1;
}

.legendEntryText {
  font-size: 12px;
}
.legendEntryText::first-letter {
  text-transform: uppercase;
}

.slider {
    /* width: 100%;
    height: 100%; */
    background-color: white;
    border: 1px solid var(--secondary-element)
}

.slide {
    fill: #eee;
}

.slider-btn {
    width: 50px;
    height: 50px;
}

.cursor {
    stroke:#222;
    stroke-width: 1;
    fill: #fff;
    rx: 4px;
    cursor: pointer;
    transition: fill 0.5s linear;
}

.cursorSelected{
    fill: #222;
}

.cursorLine {
    stroke: #222;
    stroke-width: 1;
}


.currentSequenceLevel{
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
}

.currentSequenceLabel{
    font-size: 11px;
}

.border-dark-blue {
    border-color: var(--dark-blue) !important;
}

.border-black{
  border-color: #000 !important;
}