.territory-percentage-panel {
  grid-column: 17 / span 3;
  display: flex;
  flex-wrap: wrap;
}

.territory-percentage-panel .radio-button-down, .territory-percentage-panel .radio-button-up {
  display: grid;
}

.categories-panel {
  display: flex;
}

.categories-panel * {
  padding-right: 1.2em;
}
