#main--bones{
  margin-top:4em;
}

#main--bones h1{
  margin-top:1em;
}

.legend {
  border:1px solid black;
  padding:1em;
}


.compass-credits p{
font-size:1em;
line-height: 1.5em;
}