.main-menu {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.main-menu:hover {
  background-color: var(--dark-blue);
  color: #ffffff;
}

.main-menu a:hover{
  color:var(--bg);
}

.main-menu-inner {
  cursor: pointer;
}

.index-menu-open-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}