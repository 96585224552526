
.tool-type-box {
  font-weight: 400;
  border-radius : 20px;
  margin-bottom:2em;
  padding : 0.5em;
  border:var(--app-border);
  color: var(--text-color);
}

.tool-cell {
  padding:4em 4em 0em 0em;
}

.image{
    margin:3em 0em;
    border:var(--app-border);
    width: 50%;
}
