
.territory-doubt-main-options-subpanel {
  background-color: var(--bg);
  grid-column: 11 / span 7;
  display: flex;
  flex-wrap: wrap;
}

.territory-doubt-main-options-subpanel *{
width: 25%;
margin-right:0.5em;
margin-bottom: 0.5em;
}


.territory-doubt-main-options-subpanel .radio-button-down, .territory-doubt-main-options-subpanel .radio-button-up  {
  display: grid;
}
