

:root {
  --compass-width-onepercent : 1%;
  --panel-border-width-units : 6;
  --panel-border-width : calc(var(--compass-width-onepercent) * var(--panel-border-width-units));
  --description-panel-width-units : 30;
  --description-panel-width : calc(var(--compass-width-onepercent) * var(--description-panel-width-units));
  --sliding-panel-width-units : calc(100 - var(--description-panel-width-units));
  --sliding-panel-left-padding-units : calc(100 * var(--panel-border-width-units) / var(--sliding-panel-width-units));
  --sliding-panel-left-padding : calc(var(--compass-width-onepercent) * var(--sliding-panel-left-padding-units));
}




.compass-main-description {
  padding-left: 70px;
  padding-top: 20px;
  padding-right: 70px;
  position: absolute;
  height: calc(100% - var(--navigation-height));
  background-color: var(--cool-bg);
  width: 30%;
  overflow-x: hidden;
  margin-top:0px !important;
  padding-top:3rem !important;
}


@media screen and (max-width: 1024px){
  .compass-main-description {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.panel1-open {
  left : calc(var(--description-panel-width) + 0 * var(--panel-border-width));
  width: 58vw;
}

.panel1-closed {
  left : calc(100% - 3 * var(--panel-border-width));
  width: 12vh;
  transition: ease-out 2s;
}

.panel1-closed > .sliding-panel-main-text{
  color: transparent;
}

.panel2-closed > .sliding-panel-main-text{
  color: transparent;
}

.panel3-closed > .sliding-panel-main-text{
  color: transparent;
}

.panel2-open {
  width: 58vw;
  left : calc(var(--description-panel-width) + 1 * var(--panel-border-width));
}

.panel2-closed {
  left : calc(100% - 2 * var(--panel-border-width));
  width: 12vh;
  transition: ease-out 0.7s;
}

.panel3-open {
  width: 58vw;
  left : calc(var(--description-panel-width) + 2 * var(--panel-border-width));
}

.panel3-closed {
  left : calc(100% - 1 * var(--panel-border-width));
  width: 12vh;
  background-color : var(--cool-bg);
  transition: ease-out 0.7s;
}

.compass-flux-modal{
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--cool-bg);
  right: 0;
  overflow-y: auto;
  bottom: 0;
  z-index: 1200;
}

svg.compass {
  overflow: hidden;
  vertical-align: middle;
  transform: rotate(270deg);
}
