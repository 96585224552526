:root {
  --help-side-panel-width: 340px;
}

.help-side-panel {
  padding: 20px;
  height: calc(100% - var(--navigation-height) - var(--navigation-height));
  position: fixed;
  top: var(--navigation-height);
  max-height: 100%;
  width: var(--help-side-panel-width);
  background-color: #FFFFFFE3;
  z-index: 950;
  /* to overcome z-index=10000 of 'navigations' style */
  transition: right 1s;
  overflow-y: hidden;
  overflow-x: hidden;
  opacity: 0.90;
  border-left: 1px solid black;
  backdrop-filter: blur(10px);
}

.help-side-panel-full {
  height: calc(100% - var(--navigation-height));
}

.help-side-panel-open {
  right: 0vw;
}

.help-side-panel-closed {
  right: -340px;
}

.help-side-panel p {
  font-size: 14px;
  line-height: 18px;
}

.help-side-panel ol {
  margin: 0;
}

.help-side-panel li {
  font-size: 14px;
  margin: 0;
  line-height: 18px;
}

.help-side-panel h1 {
  margin-top: 18px;
  font-size: 21px;
  color: var(--dark-blue);
  margin-bottom: 0.2em;
}

.help-side-panel h2 {
  margin: 0;
  font-size: 1.6em;
  color: var(--dark-blue);
}

.help-side-panel h3 {
  margin: 2em 0 0.5em 0;
  font-size: 1.4em;
  color: var(--dark-blue);
  font-style: italic;
}

.help-side-panel h4 {
  margin: 0;
  font-size: 1.3em;
  color: black;
  margin: 2em 0 0.5em 0;
  font-style: italic;
  text-transform: none;
}

.help-side-panel h5 {
  margin-bottom: 1em;
  font-size: 1.2em;
  font-weight: 100;
  color: var(--dark-blue);
  line-height: 1.4em;
}

.img-fluid {
  margin: 1em 0 1em 0;
  max-width: 70%;
}

.small {
  width: 30%;
}

.big {
  margin: 1em 0 1em 0;
  width: 100%;
}

.st-box {
  background-color: var(--bg);
  border: 1px solid black;
  border-radius: 0px;
  padding: 1.5rem;
  margin: 1rem 0;
  position: relative;
}

.highlight {
  color: black;
  font-size: 1.2em;
  font-style: italic;
}

.st-box:before {
  content: "";
  width: 100%;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.nebbia_bright {
  color: #5151fc;
}

.generico_non_terrestre_color_scale_end {
  color: red;
  font-size: 1.4em;
  line-height: 1.4em;
}

.tab-info {
  width: 50%;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--dark-green);
  background: none !important;
  font-size: 10px;
}

.tab-info:hover {
  border-bottom: 1px solid var(--dark-blue) !important;
  border-right: none;
  border-left: none;
  border-top: none;
}

.tab-content{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 68vh;
  padding-bottom: 20px;
}

@media (min-width: 1799px) and (max-width: 1999px){
  .tab-content{
    height: 70vh;
  }
}

@media (min-width: 2000px){
  .tab-content{
    height: 72vh;
  }
}



.help-side-panel-full > div > .tab-content{
  height: 75vh !important;
}


.tab-info.active {
  text-align: center;
  background: none !important;
  border-top: none;
  font-weight: bold;
  border-left: none;
  color: var(--dark-blue) !important;
  border-right: none;
  border-bottom: 2px solid var(--dark-blue) !important;
}

.number-tappa-active {
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  font-size: 13px;
  color: #fff;
  text-align: center;
  background: var(--dark-blue);
}

.number-tappa {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  font-size: 13px;
  border: 1px solid var(--dark-green);
  color: var(--dark-green);
  text-align: center;
  background: var(--white);
  transition:1s ease;
}

.number-tappa:hover{
  background: var(--dark-blue);
  color:var(--white);
  border: 1px solid var(--white);
}

.link-tappa:hover{
  text-decoration: none;

}

small{
  color: #404040;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}


.badge-legenda-small{
  color: #777777;
  font-weight: normal;
  text-transform: initial;
  line-height: 5px;
}

small .badge-legenda-small{
  color: red; 
}

.badge-legenda-name{
  font-size: 10px;
  /* TOlto per problema Le cosmicomiche */
  /* text-transform: lowercase; */
}

.badge-legenda-name::first-letter{
  text-transform: uppercase;
}


