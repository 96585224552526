:root {
  --loadingA: var(--dark-blue);
  --loadingA: #afaff9;
  --loadingB: #ffffff;
}

.main {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  width: 100%;
  height: var(--navigation-height);
  box-sizing: border-box;
  border-bottom: 1px solid black;
  background-color: white;
  position: absolute;
  top: 0;
}
.header > div {
  grid-column: 2 / span 22;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.loadingMessage {
  text-align: center;
	color: var(--loadingA);
}
.bgAnimated {
  width: 300vw;
	height: 100vw;
  display: grid;
  background: linear-gradient(-45deg, var(--loadingA) 0%, var(--loadingB) 25%, var(--loadingA) 50%, var(--loadingB) 75%, var(--loadingA) 100%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  animation-name: bg-animation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes bg-animation {
  from {
    left:-200vw;
  }
  to {
    left:0vw;
  }
}
