.scrollable-column-2 {
  border-left: var(--app-border);
  padding: 4em 4em 13em 8em;
  position: fixed;
  height: calc(100% - var(--navigation-height) - var(--footer-height));
  left: 30%;
  overflow: scroll;
}

.scrollable-column {
  border-right: var(--app-border);
  grid-column: 1fr;
  padding: 7em 4em 13em 8em;
  overflow: scroll;
  /* Firefox */
}

.scrollable-column-3 {
  padding: 7em 4em 13em 8em;
  position: fixed;
  height: 100%;
  overflow: scroll;
}
