.atlas-intro {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 4fr;
  height: calc(100vh - var(--navigation-height));
}

.atlas-intro-scrollable {
  display: grid;
  position: absolute;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 4fr;
  width: 100%;
  height: calc(2 * (100vh - var(--navigation-height)));
}

.atlas-intro-sidebar {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
}

.atlas-intro-sidebar2 {
  display: grid;
  grid-template-rows: 1fr 0.375fr 0.625fr;
  grid-template-columns: 1fr;
  height: calc(2 * (100vh - var(--navigation-height)));
  position: relative;
  background-color: #FFA500;
}

.atlas-intro-sidebar3 {
  display: grid;
  grid-template-rows: 0.375fr 0.625fr 0.625fr;
  grid-template-columns: 1fr;
  height: calc(1.5 * (100vh - var(--navigation-height)));
  position: relative;
}

.atlas-intro-sidebar4 {
  display: grid;
  grid-template-rows: 0.375fr 0.625fr;
  grid-template-columns: 1fr;
  height: calc(100vh - var(--navigation-height));
  position: relative;
  transition: 1s ease;}

.atlas-intro-row-grid {
  position: relative;
  display: grid;
  grid-template-rows: 4fr 1fr 1fr 1fr 1fr;
  transition: 1s ease;
}

.atlas-intro-scrolling-cell-grid {
  position: relative;
  display: grid;
  grid-template-rows: 4fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}


.atlas-intro-sidebar-monoblock {
  background: #FFA500;
  position:relative;
  width: 100%;
  display:grid;
  padding: 100px;
}

.atlas-intro-sidebar-monoblock-2 {
  background: #FFA500;
  position:relative;
  width: 100%;
  padding-left:100px;
  padding-top:100px;
  padding-right:50px;
  padding-bottom:50px;
}

.atlas-intro-cell-grid {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.atlas-intro-cell-grid2 {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-left : 2px solid black;
}

.atlas-intro-cell2 {
  border-top : 1px solid black;
  border-right : 1px solid black;
  justify-content: center;
  text-align : center;
  align-items: center;
  display:flex;
}

image .atlas-intro-cell2 {
  height:20%;
}

.atlas-intro h5{
  opacity:1;
}

.atlas-intro-cell3 {
  border-right:2px solid black;
  justify-content: center;
  text-align : center;
  align-items: center;
  display:flex;
}