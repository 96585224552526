.compass-button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* cursor: not-allowed; */
}

.compass-button:hover {
  background-color: var(--dark-blue);
  color: #ffffff;
}

.compass-button a:hover{
  color:var(--bg);
}

