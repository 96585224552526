
.phenomena-intro-grid {
  display : grid;
  grid-template-rows : 1fr;
  grid-template-columns : 1fr 2fr;
  height: calc(100vh - var(--footer-height));
  border-bottom: 1px solid #000;
  margin-top: -40px;
  padding-top: 40px;
}

.itineraries-subgrid {
  padding:4em 8em 4em 5em;
  grid-template-rows : 1fr 1fr 1fr;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.itineraries-subgrid h1 {
  white-space: nowrap;
  color: var(--dark-blue);
}

.subheaders-container {
  display : grid;
  grid-template-columns : 1fr 1fr;
  overflow : hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.currentPhase{
padding:4em 8em 4em 4em;
border-right:var(--app-border);
}
