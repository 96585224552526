.options-container {
}

.options-container > .dropdown {
  width: 100%;
  height: 100%;
}

.options-container > .dropdown > button {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.options-container > .dropdown > button:focus {
  background-color: black;
  box-shadow: none !important;
}

.btn-primary {
  color: inherit;
  background-color: inherit;
  border: none;
  text-align: inherit;
  /* text-transform: uppercase; */
  font-size: 1.1em;
  padding: 0;
}

.btn-primary span.micro-title {
  font-style: italic;
  font-size: 0.6em;
  display: block;
}

.btn-primary:hover {
  color: var(--bg);
  background-color: black;
  border: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: black;
  opacity: 0.4;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: var(--bg);
  border: none;
}

.dropdown-chessboard {
  width: 25%;
  height: 59px;
  border-right: 1px solid #000 !important;
}

.dropdown-toggle::after {
  content: none;
}

.current-selection {
  font-weight: 600;
}

.dropdown-menu {
  width: 100%;
}

.rbt-token {
  background-color: black;
  border: 0;
  border-radius: 2px;
  color: var(--dark-blue);
  display: inline-block;
  margin: 0 3px 3px 0;
  position: relative;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: black;
}
