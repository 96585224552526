
.territory-description-subpanel {
  grid-column: 4 / span 3;
  padding-top: 18px;
  padding-left: 40px;
}

@media (max-width: 1024px){
  .territory-description-subpanel {
    line-height: 14px;
  }
}

.description-link-group {
  width : 100%;
  display : flex;
  flex-direction : row;
}

.title-subpanel-territory{
  text-transform: none;
  font-size: 14px;
}
.step-link {
  text-decoration : underline;
  margin-right:2em;
}
