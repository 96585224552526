
.toggle-button {
  display : flex;
  justify-content : center;
  align-items : center;
  cursor : pointer;
  font-size: 1em;
}

.toggle-button-up {
  color : black;
  background-color : transparent;
  border-right-color : black;
  cursor : pointer;
  font-size: 1em;
  font-weight:600;
}

.toggle-button-down {
  color : var(--bg);
  background-color : black;
  border-right-color : white;
  cursor : pointer;
  font-size: 1em;
}

.micro-title {
  font-style: italic;
  font-size: 0.6em;
  display: block;
}

.toggle-button-drop-up {
  color : black;
  background-color : white;
  border-bottom : 1px solid black;
  cursor : pointer;
  font-size: 1em;
}

.toggle-button-drop-down {
  color : white;
  background-color : black;
  border-bottom : 1px solid black;
  cursor : pointer;
  font-size: 1em;
}
