main {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  font-size: var(--text-size);
}

main svg {
  grid-column: 1 / span 24;
}

main article {
  grid-column: 2 / span 14;
  margin-bottom:2em;
}

main article h3 {
  margin-bottom:1em;
  margin-top:2em;
}

.focus {
  grid-column: 2 / span 14;
  margin-bottom:2em;
}

.focus-2 {
  grid-column: 2 / span 18;
  margin-bottom:2em;
}

.viz--meta {
  grid-column: 2 / span 22;
  margin-top: 4em;
}

.viz--info {
  margin-top: 4em;
  height: 5em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.sheet--info {
  grid-column: 2 / span 10;
  margin-top: 4em;
  height: 5em;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}


.viz--pic{
  margin:2em 0em 2em 0em;
  grid-column: 2 / span 10;
  width: 100%;
}

.viz--info p {
  margin: 0;
}

#main--shape #type-button {
  text-align: right;
}

#main--shape #type-button button {
  background: var(--bg);
  border: 1px solid var(--text-color);
  text-transform: uppercase;
  padding: 0.5em 1em;
  transition: 0.3s ease;
}

#main--shape .divided {
  background: var(--text-color) !important;
  color: var(--bg);
}

#main--shape .viz--info #type-button button:hover {
  cursor: pointer;
}

.DELETEsticky-element {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 60px;
}
