

::-webkit-scrollbar {
    display: none; /* chrome */
}

.grid-with-scrollable-left-column {
  display : grid;
  grid-template-rows : 1fr;
  grid-template-columns : 1fr 1fr;
}

.unscrollable-column {
  left:130%;
  padding:7em 4em 4em 8em;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
