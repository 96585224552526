.faded {
  opacity: .2 !important;
}

/* .tick > text {
  display: none;
} */

#matrice-dei-luoghi .x-axis .tick:not(:nth-of-type(5n)):not(:first-of-type) > text {
  display: none;
}

#matrice-dei-luoghi .y-axis .tick {
  cursor: pointer;
}

.reset-rect {
  fill: transparent;
}

.cat-selected {
}

.label.make-visible {
  display: block !important;
}

.node {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.presumed {
  pointer-events: none;
}

.tick.selected {
  font-weight: bold;
}

.label {
  fill:#666;
  font-weight: 300;
}

.label.zoom-selected {
  display: block !important;
}
.label.selected {
  display: block !important;
  fill:black;
  font-weight: 500;
}

.there-is-selection .node, .there-is-filter .filtered {
  opacity: 0.25;
}

.node.selected {
  opacity: 1 !important;
}
