html {
  font-size: 16px;
  --c-bg: #fffefa;
  --c-romanzo: #5151fc;
  --c-racconti: #FF3366;
  --c-ibrido: #F2CA22;
  --c-riscritture: #C890F4;
  --c-racconti-protagonista: #FFA500;
  --c-racconti-cornice: #8ae297;
  --c-saggi: #6E94F4;
  --c-postumi: #0000ff;
  --c-unita: #A0BAF9;
  --c-cds: #ff6c39;
  --c-repubblica: #dab720;
  --c-text: #000;
  --cool-bg: #fffefa;
}

#visualisation-container svg {
  position: sticky;
  top: var(--navigation-height);
}

#main--sinuous h2 {
  margin: 0;
}

#main--sinuous .label {
  font-size: 0.7rem;
  text-anchor: middle;
  letter-spacing: 0.3px;
  fill: var(--text-color);
}

#main--sinuous .label.year {
  font-size: 0.7rem;
}

#main--sinuous .decade.in-focus > .label.year.the-first {
  animation-name: in-focus-label;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

#main--sinuous .label.small {
  font-size: 0.7rem;
}

#main--sinuous .label.character {
  font-size: 0.7rem;
  font-family: "HK Grotesk";
  font-style: normal;
  text-anchor: middle;
}

#main--sinuous .label.white-shadow {
  stroke: var(--cool-bg);
  stroke-width: 0.125rem;
  fill: var(--bg);
  stroke-linejoin: round;
}

#main--sinuous .info {
  font-size: 0.7rem;
  font-weight: 400;
}

#main--sinuous .year {
  font-size: 0.5rem;
}

#main--sinuous .guide-line,
.thread {
  fill: none;
  stroke: var(--c-text);
  stroke-width: 1px;
}

#main--sinuous .decade-arc {
  stroke-dasharray: 1 4;
  stroke-linecap: round;
  stroke-dashoffset: 0;
}

#main--sinuous .decade.in-focus .decade-arc {
  -webkit-animation-name: arcs-direction;
  animation-name: arcs-direction;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

#main--sinuous .decade-arc.start {
  marker-end: url(#arrow-head-big);
}

#main--sinuous .anni90 .decade-arc.start {
  marker-end: none;
}

#main--sinuous .previous-publication {
  fill: none;
  stroke: #5d6d7e;
  stroke-width: 1px;
  stroke-dasharray: 5 4;
  marker-end: url(#arrow-head);
}

#main--sinuous .article.ghost-node {
  display: none;
  fill: red;
}

#main--sinuous .work-title {
  cursor: pointer;
  font-style: italic;
  display: inline;
  border: 1px solid;
  border-radius: 4px;
  padding: 0px 3.5px;
  white-space: nowrap;
  transition: all 0.3s ease;
}

#main--sinuous .scrollytelling-container h5 {
  font-size: 2em;
  font-weight: normal;
  margin-bottom: 1em;
}

#main--sinuous .scrollytelling-container .racconti {
  color: var(--c-racconti);
  border-color: var(--c-racconti);
}

#main--sinuous .scrollytelling-container .romanzo {
  color: var(--c-romanzo);
  border-color: var(--c-romanzo);
}

#main--sinuous .scrollytelling-container .ibrido {
  color: var(--c-ibrido);
  border-color: var(--c-ibrido);
}

#main--sinuous .scrollytelling-container .riscritture {
  color: var(--c-riscritture);
  border-color: var(--c-riscritture);
}

#main--sinuous .scrollytelling-container .racconti-protagonista {
  color: var(--c-racconti-protagonista);
  border-color: var(--c-racconti-protagonista);
}

#main--sinuous .scrollytelling-container .racconti-cornice {
  color: var(--c-racconti-cornice);
  border-color: var(--c-racconti-cornice);
}

#main--sinuous .scrollytelling-container .saggi {
  color: var(--c-saggi);
  border-color: var(--c-saggi);
}

#main--sinuous .scrollytelling-container .postumi {
  color: var(--c-postumi);
  border-color: var(--c-postumi);
}

#main--sinuous .work circle {
  cursor: pointer;
}

#main--sinuous .work circle.in-focus {
  animation-name: in-focus;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

#main--sinuous .work circle.unfinished {
  stroke-dasharray: 2 2;
}

#main--sinuous .work .volume-cover {
  opacity: 1e-6;
  pointer-events: none;
  transition: opacity 0.35s ease;
}

#main--sinuous .work:hover .volume-cover,
.work.work-in-focus .volume-cover {
  opacity: 1;
}

/* Other */
#main--sinuous .hidden {
  display: none;
}

#main--sinuous .scrollytelling-container {
  /* padding-top: 50vh;
  padding-bottom: 50vh; */
  /* z-index: 900;
  max-width:50vh; */
}

#main--sinuous .scrollytelling-container p {
  font-size: 1.2rem;
}

#main--sinuous .how-to {
  font-size: 1.2rem;
  font-family: "HK Grotesk", sans-serif !important;
}

#main--sinuous .how-to * {
  font-family: "HK Grotesk", sans-serif !important;
}

#main--sinuous .how-to > div {
  background-color: var(--cool-bg);
  padding: 0.8rem;
  border: 1px solid var(--c-text);
  border-radius: 3px;
}

#main--sinuous .how-to p {
  font-size: inherit;
}

#main--sinuous .decade-txt {
}

#main--sinuous .intro {
  margin-bottom: 2em;
}

#main--sinuous .item {
  padding-bottom: 50vh;
}

#main--sinuous .item p:last-child {
  margin-bottom: 0;
}

#main--sinuous .item-focus {
  margin-bottom: 25vh;
}

/* LEGEND */

#main--sinuous .legend {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 2rem;
  position: sticky;
  top: calc(var(--navigation-height) + (var(--navigation-height)) * 0.5);
  background: var(--cool-bg);
  margin-top: 2em;
  margin-bottom: 2rem;
  z-index: 10000;
  justify-content: flex-end;
  padding: 0em 0 2em 0;
  border: none;
}

#main--sinuous .legend .legend-content {
  display: grid;
  justify-content: center;
  justify-items:center;
  grid-template-columns: repeat(3, 20%);
  grid-column-gap: 15%;
  grid-template-rows: 28px 42px 1fr 1fr 1fr 48px 72px 42px 48px;
  grid-row-gap: 0;
  height: 2rem;
  width: 100%;
  transition: height 0.35s ease;
  overflow: hidden;
  border: 1px solid var(--navigation-borders);
  background-color: var(--cool-bg);
}

#main--sinuous .legend.open .legend-content {
  background: var(--cool-bg);
  height: calc(100vh - 116px);
  overflow: visible;
}

#main--sinuous #legend-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10000;
  margin-top: 0.3rem;
  position: absolute;
  right: 1rem;
}

#main--sinuous #legend-button::before {
  content: "";
  display: block;
  background-color: var(--c-text);
  height: 2px;
  position: relative;
  left: 1px;
  width: 32%;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

#main--sinuous #legend-button::after {
  content: "";
  display: block;
  background-color: var(--c-text);
  height: 2px;
  position: relative;
  right: 1px;
  width: 32%;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

#main--sinuous .legend.open #legend-button::before {
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

#main--sinuous .legend.open #legend-button::after {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

#main--sinuous .legend h2 {
  font-size: 1.2rem;
  line-height: 2rem;
  grid-column: span 3;
  text-align: center;
  align-self: center;
}

#main--sinuous .legend h4 {
  font-family: "HKGrotesk";
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  grid-column: span 3;
  margin: 0;
}

#main--sinuous .legend h5 {
  font-family: "HKGrotesk";
  font-size: 0.6rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  text-align: center;
  grid-column: span 3;
  margin: 0;
}

#main--sinuous .legend h6 {
  font-family: "HKGrotesk";
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
  text-align: center;
  width: 300%;
  margin-left: -100%;
  margin-top: 0.5rem;
}

#main--sinuous .legend-item {
  width: 80%;
  height:80%;
  /* margin-top:3em; */
}

#main--sinuous .legend-circle {
  background: var(--cool-bg);
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 0;
  display: block;
  border-radius: 50%;
  padding-bottom: calc(100% - 2px);
  background-size: cover;
  box-shadow: none !important;
}

#main--sinuous .legend-article-circle {
  background: var(--cool-bg);
  border: 2px solid transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 30%;
  height: 0;
  display: block;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 5px;
  padding-bottom: calc(30% - 4px);
}

#main--sinuous .legend-article-circle.lgnd::after {
  content: "Altro";
  position: relative;
  font-size: 0.7rem;
  left: 20px;
  top: -5.5px;
}

#main--sinuous .legend-article-circle.lgnd.narrative::after {
  content: "Narrativa";
  position: relative;
  font-size: 0.7rem;
  left: 20px;
  top: -5.5px;
}

#main--sinuous .legend-article-circle.lgnd.it::after {
  content: "Altro";
}
#main--sinuous .legend-article-circle.lgnd.en::after {
  content: "Other";
}

#main--sinuous .legend-article-circle.lgnd.narrative.it::after {
  content: "Narrativa";
}
#main--sinuous .legend-article-circle.lgnd.narrative.en::after {
  content: "Fiction";
}

@-webkit-keyframes arcs-direction {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -9;
  }
}

@keyframes arcs-direction {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -9;
  }
}

@-webkit-keyframes fade-in-radius {
  from {
    r: 0;
  }

  to {
    r: 4;
  }
}

@keyframes fade-in-radius {
  from {
    r: 0;
  }

  to {
    r: auto;
  }
}

@keyframes in-focus {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.125);
  }
}

@keyframes in-focus-label {
  0% {
    font-size: 0.45rem;
  }

  100% {
    font-size: 0.55rem;
  }
}

@media screen and (max-width: 480px) {
  #main--sinuous .legend.open .legend-content {
    height: 70vh;
    height: calc(100vh - 116px);
  }
}

@media screen and (min-width: 768px) {
  #main--sinuous #visualisation-container {
    position: sticky;
    top: var(--navigation-height);
  }

  #main--sinuous .scrollytelling-container {
    width: 100%;
  }

  #main--sinuous .legend.open .legend-content {
    height: 78vh;
    height: calc(100vh - 116px);
    width: 100%;
    display: grid;
    row-gap: 0;
  }

  #main--sinuous .legend {
    position: sticky;
    top: calc(var(--navigation-height) + -0.1em);
    width: 64vh;
  }

#main--sinuous .legend-item {
  width:60%;
  height:60%;
  /* margin-top:3em; */
}


  #main--sinuous .how-to > div {
    width: 64vh;
  }

  #main--sinuous .label {
    font-size: 0.6rem;
  }

  #main--sinuous .label.small {
    font-size: 0.55rem;
  }

  #main--sinuous .article {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  #main--sinuous .legend,
  .legend .legend-content {
    border: none;
    padding: 0;
    border: none;
  }

  #main--sinuous .legend {
    position: sticky;
    top: calc(var(--navigation-height) + -0.1em);
    width: 100%;
  }

  #main--sinuous .legend.open .legend-content {
    height: 88vh;
    height: calc(100vh - 116px);
    width: 100%;
  }

  #main--sinuous .how-to > div {
    background-color: none;
    width: 100%;
  }

  #main--sinuous .item {
    padding-bottom: 35vh;
  }

  #main--sinuous .item > p {
    background-color: transparent;
    border: none;
    padding: 0;
    border-radius: 3px;
  }

  #main--sinuous .item p:last-child {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 993px) {
  #main--sinuous .legend.open .legend-content {
    height: 85vh;
    height: calc(100vh - 116px);
    width: 100%;
  }

}

@media screen and (min-width: 1024px) {
  #main--sinuous .legend.open .legend-content {
    height: 88vh;
    height: calc(100vh - 116px);
    width: 100%;
  }
}

@media screen and (max-width: 1980px) {
  #main--sinuous .legend.open .legend-content {
    height: 80vh;
    height: calc(100vh - 116px);
    width: 100%;
  }
}

@media screen and (max-width: 2048px) {
  #main--sinuous .legend.open .legend-content {
    height: 78vh;
    height: calc(100vh - 116px);
    width: 100%;
  }
}