.splash-container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-template-rows: 80px repeat(8, 1fr);
}

.bg-svg {
  grid-column: 1 / end;
  grid-row: 1 / end;
}

.line {
  fill: none;
  stroke: black;
  stroke-width: 1;
  stroke-dasharray: 2 4;
}

.pack {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 50%;
  height: 66.6%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.arrow-down {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-down div {
  border: 1px solid var(--dark-blue);
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logos-left {
  /* grid-column: 1 / 2;
    grid-row: 8 / end; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(50px + 2rem);
  width: auto;
  position: fixed;
  bottom: 0;
  left: 0;
}

.logos-center {
  /* grid-column: 3 / 5;
    grid-row: 8 / end; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(50px + 2rem);
  width: auto;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.logos-right {
  /* grid-column: 6 / end;
    grid-row: 8 / end; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(50px + 2rem);
  width: auto;
  position: fixed;
  bottom: 0;
  right: 0;
}

.disabled-link {
  /* cursor: not-allowed; */
  /* pointer-events: none; */
}

.logos {
  padding: 3rem 1.5rem;
}
