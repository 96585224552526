.axis {
  transition: opacity 0.3s ease;
}

.tick text {
  font-size: 1.3em;
  font-family: 'HKGrotesk', sans-serif;
}


#doubtTypes .tick line {
  stroke-dasharray: 2;
  stroke: black;
}

#doubtTypes{
  grid-column: 1 / span 12;
}

.x.axis{
  border:1px solid grey;
}


div.tooltip {
    position: absolute;
    text-align: center;
    width: 60px;
    height: 28px;
    padding: 2px;
    font: 12px sans-serif;
    background: lightsteelblue;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
}
