.territory-space-main-options-subpanel {
  background-color: var(--bg);
  grid-column: 11 / span 7;
  height: 100px;
}

.territory-space-main-options-subpanel *{
  margin-right:0.5em;
  margin-bottom:0.5em;
}


.categories-panel *{
    width:33.3%;
  }
