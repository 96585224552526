.territory-shape-main-options-subpanel {
  background-color: var(--bg);
  grid-column: 11 / span 7;
}

.categories-panel-shape {
  display: flex;
  margin-bottom: 1.2em;
  margin-top: 1em;
}

.categories-panel-shape * {
  width: 100px;;
}

.territory-shape-main-options-subpanel .radio-button-down, .territory-shape-main-options-subpanel .radio-button-up {
  display: grid;
  width: 50%;
}
