#main--sinuous{
  margin-top:4em;
}

#main--sinuous h1{
  margin-top:1em;
}


#main--sinuous .references{
  margin-top:2em;
  font-style: italic;
  font-size: 1em;
}
#main--sinuous .references p{
  margin-top:1em;
  font-style: italic;
  font-size: 1em;
}
