
.territory-space-panel {
  background-color : var(--bg);
  grid-column: 5 / span 13;
}

.territory-percentage-panel-double{
  grid-column: 19 / span 3;
  display: flex;
  flex-direction: column;
  padding-top: 37px;
}

.territory-percentage-subpanel-double{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
