
.sliding-panel {
  grid-template-columns : 1fr 3fr;
  grid-template-rows : 1fr;
  background-color : var(--cool-bg);
  position : fixed;
  transition: left 1s;
  height: calc(100% - var(--navigation-height));
  border-left: var(--app-border);
  display: flex;
}

.rotated-title {
  position: absolute;
  cursor: pointer;
  padding: 90px;
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    line-height: 10px;
    height: 10px;
    width: 10px;
    overflow: visible;
    white-space: nowrap;
}

  .sliding-panel-main-text{
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 90px;
    padding-right: 70px;
  }

  @media screen and (max-width: 1024px){
    

    .rotated-title {
      padding: 80px;
    }
  }

  .sliding-panel-hide {
    display : none;
  }
