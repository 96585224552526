:root {
  --compass-panel-width: 100vw;
}

.compass-panel {
  padding: 0;
  position: fixed;
  top: 0px;
  max-height: 100%;
  width: var(--compass-panel-width);
  height : 100vw;
  background-color: var(--bg);
  z-index: 1200;
  /* to overcome z-index=10000 of 'navigations' style */
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 1;
}

.compass-panel-open {
  right: 0vw;
}

.compass-panel-closed {
  right: calc(0vw - var(--compass-panel-width));
}