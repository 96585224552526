/* App.css */

/* common CSS */
:root {
  --bg: #fbfbfc;
  --calvino-red: tomato;
  --dark-green: black;
  --cool-bg-bg: #f3f1ec;
  --cool-bg: #fffffa;
  --white: #ffffff;
  --dark-blue: #5151fc;
  --small-text: #3f3e4c;
  --secondary-element: #c6cacf;
  --header-height: 66px;
  --violet-index-home: #afaff9;
  --navigation-height: 58px;
  --footer-height: 18px;
  --navigation-height-by-2: calc(var(--navigation-height) * 2);
  --app-border-top: 6px;
  --navigation-borders: black;
  --text-color: black;
  --app-border: 1px solid black;
  --app-border-bold: 1px solid rgb(143, 131, 131);
  --button-border: 1px solid var(--dark-blue);
  --text-size: 14px;
  --main-menu: var(--dimGold);
  font-size: 14px;
  font-family: "HKGrotesk", sans-serif;
}

title {
  text-transform: uppercase;
}

.col-lg-8 {
  padding-left: 0px;
}

/* ELEMENTI COMUNI */
a {
  color: var(--dark-blue);
  /* transition: all 0.35s ease; */
}

a:hover {
  color: var(--dark-blue);
}

a:focus {
  text-decoration: underline;
  color: var(--dark-blue);
  transition: color 0.5s ease;
}

.link {
  color: var(--dark-blue);
}

a.link:hover {
  color: var(--text-color);
}

a.top-menu {
  color: var(--text-color);
}

a.active {
  color: var(--dark-blue);
}

/* OLD p {
  font-size: 1.4em;
  line-height: 1.4em;
} */

/* p.intro {
  font-size: 1.7em;
  line-height: 1.5em;
  margin-bottom: 0 !important;
} */

::selection {
  color: var(--bg);
  background-color: var(--dark-blue);
}

html,
body {
  height: 100vh;
  line-height: normal;
}

body {
  margin: 0;
  font-family: "HKGrotesk", sans-serif;
  overflow-x: hidden;
  background-color: var(--cool-bg);
}

/* TYPOGRAPHY STYLES */
h1 {
  margin: 0.7142857143em 0;
  font-size: 2.5em;
  color: var(--text-color);
  font-weight: 600;
  letter-spacing: 0.3px;
}
h2 {
  margin: 0.7142857143em 0;
  line-height: 2rem;
  font-size: 1.5em;
  color: var(--dark-blue);
  font-weight: 500;
}
h3 {
  font-size: 1.28rem;
  line-height: 2.14rem;
  color: #404040;
}
p {
  font-size: 1.2857142857rem;
  line-height: 2.1428571429rem;
}
p.small {
  font-size: 1rem;
  line-height: 1.2857142857rem;
}
.breadcrumb {
  color: #666;
  font-size: 0.8571428571rem;
  line-height: 2.1428571429rem;
  letter-spacing: 0.2;
}
.legend-text {
  font-size: 0.7142857143rem;
  line-height: 0.9285714286rem;
}
.message {
  font-size: 1rem;
  line-height: 1.2857142857rem;
}

.order-message{
  color:#666;
  font-style: italic;
  font-size: 0.8em;
  text-align: center;
  margin-bottom:0;
}
/* end block */

.ac-breadcrumb {
  color: #666;
  font-size: 0.8571428571rem;
  line-height: 2.1428571429rem;
  letter-spacing: 0.2;
  text-transform: uppercase;
  grid-column: 1 / span 12;
}

.legend-text {
  font-size: 0.7142857143rem;
  line-height: 0.9285714286rem;
}

.caption,
.captionLeft {
  font-size: 0.8571428571rem;
  line-height: 1.4rem;
  text-align: center;
}

.captionLeft {
  text-align: left;
}

sup {
  color: var(--dark-blue);
  font-size: 60%;
}

/* /end TYPOGRAPHY STYLES */

/* GRID */
.ac-grid-24 {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}
.ac-grid-32 {
  display: grid;
  grid-template-columns: repeat(32, 1fr);
}
.content {
  padding: 0 calc((100% / 16));
  padding-bottom: 3rem;
  padding-top: 3rem;
  grid-column: 1 / 17;

  /* new layout */
  padding: 3rem 0;
  grid-column: 4 / span 12;
}
.content.indented {
  grid-column-start: 6;
}

.contentRight {
  padding: 0 calc((100% / 16));
  padding-bottom: 3rem;
  padding-top: 3rem;
  grid-column: 8 / 20;
}

.sidebar {
  grid-column: 17 / end;
  border-left: 1px solid black;
  height: 100vh;
  /* display: flex; */
  padding: 0 calc(100% / 8);
}

.sidebarLeft {
  grid-column: 2 / 7;
  border-right: 1px solid black;
  height: 100vh;
  padding-top: 1.7em;
  padding-right: 1.7em;
  /* display: flex; */
}

.sticky-element {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: var(--navigation-height);
}
/* end grid */

.main {
  width: 100vw;
  height: 100vh;
  position: relative;
}

ol {
  list-style-type: decimal;
  list-style-position: outside;
  padding-left: 3rem;
  color: var(--small-text);
  font-size: 1.2857142857rem;
  line-height: 2.1428571429rem;
  margin: 1rem 2rem;
}

ul {
  font-size: 1.2857142857rem;
  line-height: 2.1428571429rem;
  list-style-type: none;
  padding-left: 3rem;
  margin: 1rem 0;
}

li {
  /* margin-bottom: 0.5em; */
  /* margin-top: 0.5em; */
  color: var(--list);
}

.references {
  font-size: 0.8571428571rem;
  line-height: 1.0714285714rem;
  font-weight: 600;
  margin-bottom: 2rem;
  padding-left: 0rem;
}

.references ol,
.references li {
  padding-left: 0px;
  list-style-type: none;
}

.referenceItem {
  list-style-position: inside;
  margin-bottom: 1rem;
}

.cite {
  margin-left: 3.8571428571rem;
  margin-right: 3.8571428571rem;
  color: var(--dark-blue);
  font-size: 1.2857142857rem;
  line-height: 2.1428571429rem;
  font-style: italic;
}

/* added this second style to make a proposal of a different quotation style. Builds on top of the previous, so inc ase we have to integrate this into '.cite' */
.cite2 {
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin: 2rem 0 2rem 3rem;
  hyphens: manual;
  font-style: normal;
}

.navigations {
  border-bottom: 1px solid var(--navigation-borders);
  box-sizing: border-box;
  height: var(--navigation-height);
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  z-index: 900;
  position: fixed;
}

.navigations > *:not(:last-child) {
  border-right: 1px solid var(--navigation-borders);
}

.navigations > div {
  max-height: 100%;
  display: flex;
  align-items: center;
}

.page-title {
  color: var(--dark-blue);
}

.page-title,
.options-container > .dropdown > button,
.search-component {
  padding: 0 10px;
}

.navigations > div svg {
}

.top-nav {
  position: sticky;
  background-color: var(--white);
  top: 0;
  z-index:999;
}

.the-body-viz {
  height: calc(100vh - var(--navigation-height-by-2));
  display: flex;
}

.bottom-nav {
  position: fixed;
  background-color: var(--bg);
  bottom: 0;
  border-top: 1px solid var(--navigation-borders);
  transition: bottom 0.5s ease;
}

.viz-title {
  text-transform: uppercase;
  font-size: 1.2857142857rem;
}

.pubblication-item {
  margin-bottom: 4rem;
}

.pubblication-item h5 {
  color: black !important;
}

.pubblication-item h3 {
  color: black !important;
}

.pubblication-abstract {
  margin-top: 1rem;
}

/*BUTTON*/
.button-text {
  border: var(--button-border);
  margin-top: 3em;
  padding: 15px 40px 15px 40px;
  font-size: 15px;
  font-weight: 500;
  color: var(--dark-blue);
  background-color: var(--bg-cool);
  transition: color 0.3s ease;
  text-decoration: none;
}

a.button-text,
a:visited.button-text {
  text-decoration: none;
}

.button-text:hover {
  background-color: var(--dark-blue);
  color: var(--bg);
}

.cc-btn.cc-dismiss:hover {
  color: #00c19c;
  color: #5151fc;
}

/* BOTTONI DELLE VIZ */
.btn-primary {
  font-weight: 600;
}

.btn-primary a:hover {
  font-weight: 600;
  background-color: var(--dark-blue);
}

/* FOOTER */
/* Moved into footer.js */
/* footer {
  background-color: var(--dark-blue);
  color: var(--cool-bg);
  position: sticky;
  bottom: 0;
  width: 100%;
  font-size: 0.7em;
  padding: 1.5em;
  height: var(--navigation-height);
} */

/* Removed */
/* #loghi {
  overflow-y: hidden;
}

#logo-unige {
  width: 100%;
}

#logo-polimi {
  width: 82%;
}

#logo-dd {
  width: 30%;
} */

#timeline .selection {
  fill: transparent;
  fill-opacity: 1;
  stroke: var(--text-color);
  stroke-width: 1;
}

#timeline .handle {
  fill: var(--text-color);
  height: 20px;
  opacity: 1;
  stroke: none;
  stroke-width: 5px;
  stroke: transparent;
}

.dropdown-menu * {
  border-radius: 0px;
  height: auto;
  z-index: 20;
  grid-column: 1 / span 3;
  font-size: 1.1em;
  position: relative;
  /* text-transform: capitalize; */
  text-align: center;
  border-radius: 0px;
  align-content: flex-end;
}

.dropdown-menu {
  height: auto;
  border-radius: 0px;
  border: 1px solid black;
  background-color: var(--bg);
  padding-top: 0em;
  padding-bottom: 0em;
  float: left;
}

.dropdown-item {
  font-size: 1em;
  border-bottom: 1px solid var(--dark-green);
  border-right: none !important;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu a:hover {
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bg);
  background-color: var(--dark-green);
}

.svg-inline--fa {
  font-size: 1.1em;
}

#overlay img {
  display: block;
  position: absolute;
  z-index: -9999;
  width: 15%;
  opacity: 0.5;
  /* filter: invert(12%) sepia(67%) saturate(6275%) hue-rotate(237deg) brightness(105%) contrast(98%); */
}

.text-dark-blue {
  color: var(--dark-blue);
}

.text-secondary-color {
  color: var(--secondary-element);
}

#overlay .floatLeft {
  animation: floatLeft 5s infinite;
  animation-direction: normal;
  height: auto;
}

.help-side-panel .legend {
  padding: 0;
  border: none;
}

.help-side-panel .legend p,
.help-side-panel .legend li {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.35rem;
}

.cursor-pointer {
  cursor: pointer;
}

.lang-switch {
  position: absolute;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--dark-green);
  border-left: 1px solid var(--dark-green);
  border-right: 1px solid var(--dark-green);
  justify-content: center;
  right: 0;
  font-size: 10px;
  height: 61px;
  cursor: pointer;
  width: 4.25%;
  top: var(--navigation-height);
}

@keyframes floatLeft {
  0% {
    left: 50px;
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    left: 100px;
    -webkit-animation-timing-function: ease-in;
  }

  100% {
    left: 50px;
    animation-timing-function: ease-out;
  }
}

#overlay .floatTop {
  animation: floatTop 5s infinite;
  animation-direction: normal;
  height: auto;
}

@keyframes floatTop {
  0% {
    top: 800px;
    left: 500px;
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    top: 850px;
    left: 500px;
    -webkit-animation-timing-function: ease-in;
  }

  100% {
    top: 800px;
    left: 500px;
    animation-timing-function: ease-out;
  }
}

#overlay .sizein {
  animation: sizein 2s infinite;
  animation-direction: alternate;
  height: auto;
}

@keyframes sizein {
  0% {
    width: 10%;
    left: 500px;
    top: 500px;
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    width: 11%;
    left: 500px;
    top: 500px;
    -webkit-animation-timing-function: ease-out;
  }

  100% {
    width: 10%;
    left: 500px;
    top: 500px;
    -webkit-animation-timing-function: ease-out;
  }
}

#overlay .fade {
  animation: fade 0.8s infinite;
  animation-direction: normal;
  height: auto;
}

@keyframes fade {
  0% {
    opacity: 100%;
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    opacity: 5%;
    -webkit-animation-timing-function: ease-out;
  }

  100% {
    opacity: 100%;
    -webkit-animation-timing-function: ease-out;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

@media screen and (min-width: 768px) {

}

@media screen and (min-width: 992px) {

}

@media screen and (min-width: 1024px) {

}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1440px) {
  .content {
    grid-column: 5 / span 9;
  }
}

@media screen and (min-width: 1920px) {

}



