.footer {
  background-color: var(--dark-blue);
  color: var(--cool-bg);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  /* padding: 1.5em; */
  height: var(--footer-height);
  font-size: 0.8rem;
  line-height: 1rem;
  padding: 1px 0 0 0;
}

.footer p {
  font-size: inherit;
  line-height: inherit;
  margin: 0
}
