/*
.territory-itineraries-drop-up {
  background-color : var(--bg);
  outline: 1px solid black;
  position : fixed;
  bottom: var(--navigation-height);
  width : 33%;
  height: calc(var(--navigation-height)*3);
  z-index : 2;
  display : grid;
}

.territory-itineraries-drop-up-button-grid {
  display : grid;
  grid-column : 1 / span 3;
  border-right : 1px solid black;
  font-size : 1.3em;
  text-align : center;
}
*/

.territory-itineraries-drop-up {
  background-color : white;
/*  outline: 1px solid black; */
  position : fixed;
  bottom: var(--navigation-height);
  width : 33.33%;
  border-left: 1px solid var(--dark-green);
  left:0%;
  height: calc(var(--navigation-height)*3);
  z-index : 20;
  display : grid;
  text-align : center;
}


.territory-itineraries-drop-up *{
  background-color : var(--bg);
  padding-top: 1.2em;
  border-right: 1px solid #000;
}


/* Landscape */
@media only screen
  and (min-width: 1024px)
  and (max-height: 1366px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1.5) {

.territory-itineraries-drop-up {
    width : 33.33%;
}

}
