.territory-percentage-panel {
  grid-column: 17 / span 3;
  display: flex;
  flex-direction: column;
  padding-top: 18px;
}

.territory-placeHierarchies-subpanel{
  display: flex;
  align-items: center;
  margin-top: 1em;
  width: 100%;
}

.categories-panel {
  display: flex;
  padding-top: 18px;
}

.categories-panel * {
  padding-right: 1.2em;
}
